import './styles/style.css'
import { Routes, DefaultRoute } from './view/routes'
import 'utils/analytics'

if (module.hot) {
  module.hot.accept()
}

if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode!')
}

if (Sentry && parseInt(process.env.SENTRY_ERROR_LOGGING)) {
  Sentry.init({ dsn: 'https://540e13f166c841529e919f2110b4f1d0@sentry.io/2708749' })
}

const $root = document.body.querySelector('#root')
m.route($root, DefaultRoute, Routes)

let vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

window.addEventListener('resize', () => {
  vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
})

window.addEventListener('orientationchange', () => {
  vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
})
