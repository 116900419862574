export const TextArea = ({ attrs: { autofocus } }) => {
  return {
    oncreate ({ dom }) {
      if (autofocus) {
        dom.querySelector('input').focus()
      }
    },
    view ({ attrs }) {
      return m('.py-3', [
        m('label.block.text-sm.text-gray-700', attrs.label),
        m('textarea.max-w-lg.shadow-sm.block.w-full.focus:ring-indigo-500.focus:border-indigo-500.sm:text-sm.border-gray-300.rounded-md', {
          value: attrs.value,
          max: attrs.max,
          rows: attrs.rows || 4,
          placeholder: attrs.placeholder,
          oninput (e) {
            attrs.oninput && attrs.oninput(e.target.value)
          }
        }),
        m('.text-red-600.text-sm', attrs.errorMessage)
      ])
    }
  }
}
