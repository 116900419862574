import { Breadcrumb } from 'components/Breadcrumb'
import { Button } from 'components/Button'
import { Table } from 'components/Table'
import { Svg } from 'components/Svg'
import { Pagination } from 'components/Pagination'

import { getAdminUsers } from 'api/users'

import { debounce } from 'utils/debounce'

import addIcon from 'assets/icons/outline/plus.svg'

const User = ({ attrs: { item, classes } }) => {
  return {
    view () {
      return m('.flex.flex-shrink-0.hover:bg-gray-300.cursor-pointer', {
        class: classes ? classes.join(' ') : 'bg-white',
        style: {
          height: '40px'
        },
        onclick: () => {
          m.route.set(`/users/${item.id}`)
        }
      },
      [
        m('.flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.truncate.text-sm.font-medium.text-gray-900',
          m(m.route.Link, { href: `/users/${item.id}` }, `${item.firstName} ${item.lastName}`)
        ),
        m('.flex.flex-grow.w-0.h-10.px-6.py-3.whitespace-nowrap.text-sm.text-gray-500',
          m('span', item.email)
        ),
        m('.flex.w-32.h-10.px-6.py-3.whitespace-nowrap.justify-center',
          m('span.px-2.inline-flex.text-xs.leading-5.font-semibold.rounded-full.bg-green-100.text-green-800',
            ' Active '
          )
        )
      ]
      )
    }
  }
}

export const Users = () => {
  const filters = { page: 1, search: null }

  let users
  let meta

  getAdminUsers(filters).then(data => {
    users = data.data
    meta = data.meta
  })

  const debouncedSearch = debounce(val => {
    filters.search = val.length > 2 ? val : null
    getAdminUsers(filters).then(data => {
      users = data.data
      meta = data.meta
    })
  }, 300)

  return {
    view () {
      return [
        m(Breadcrumb, {
          links: [{
            title: 'Admin Users'
          }],
          buttons: [
            m(Button, {
              size: 'sm',
              classes: ['my-2', 'mx-auto', 'whitespace-nowrap', 'text-white'],
              type: 'button',
              onclick: () => {
                m.route.set('/users/create')
              }
            }, [m(Svg, {
              classes: ['w-4', 'mx-auto']
            }, [addIcon]), 'Add Admin User'])]
        }),
        m('.p-4', { style: { height: 'calc(100% - 3rem)' } }, [
          m('div.shadow.h-full.border-b.border-gray-200.sm:rounded-lg',
            users && m(Table, {
              cols: [
                {
                  label: t('users', 'name'),
                  search: true,
                  searchOnChange: (val) => {
                    debouncedSearch(val)
                  }
                },
                { label: t('users', 'email') },
                {
                  label: t('users', 'status'),
                  classes: ['w-32 justify-center']
                }
              ],
              filters: true
            },
            [
              m('.overflow-auto',
                users.map((v, ix) => {
                  return m(User, { key: v.id, item: v, classes: [ix % 2 ? 'bg-gray-50' : 'bg-white'] })
                })
              ),
              meta && m(Pagination, {
                meta: meta,
                onpage: (page) => {
                  filters.page = page
                  getAdminUsers(filters).then(data => {
                    users = data.data
                    meta = data.meta
                  })
                }
              })
            ])
          )
        ])
      ]
    }
  }
}
