import { PageHeading, Link } from 'components'
import { Breadcrumb } from 'components/Breadcrumb'
import { Pagination } from 'components/Pagination'
import { LoadingFullScreen } from 'components/Loading/FullScreen'
import { Card } from 'components/Card'
import { Tabs } from 'components/Tabs'
import { Button } from 'components/Button'
import { Svg } from 'components/Svg'
import { Avatar } from 'components/Avatar'
import { PastActivityBar } from 'components/PastActivityBar'
import { Table, Cell } from 'components/Table'
import { VisibilityBadge, BookingStatusBadge, MeetingStatusBadge } from 'components/Badge'
import { DateTime } from 'luxon'
import { getDepartment, getDepartmentActivity, getDepartmentVenues, getDepartmentBookings, getDepartmentMeetings, getDepartmentUsers, getDepartmentBillingInstructions } from 'api/clients'
import pencilIcon from 'assets/icons/solid/pencil.svg'
import editIcon from 'assets/icons/solid/pencil-alt.svg'
import addIcon from 'assets/icons/outline/plus.svg'
import clientsIcon from 'assets/icons/outline/briefcase.svg'
import bfIcon from 'assets/icons/solid/table.svg'
import chevronUp from 'assets/icons/outline/chevron-double-up.svg'
import { debounce } from 'utils/debounce'
import { importMeetings as importMeetingsAPI } from 'api/meetings'
import { ImportApprenticeBookingsDialog } from './ImportApprenticeBookingsDialog'
import { ImportDLAPiperBookingsDialog } from './ImportDLAPiperBookingsDialog'
import { ImportMeetingsDialog } from './ImportMeetingsDialog'
import { ImportResponseDialog } from './ImportResponseDialog'
import { importApprenticeBookings, importDLAPiperBookings } from 'api/bookings'
import { notification } from 'utils/notifications'
import emailIcon from 'assets/icons/solid/mail.svg'

export const ViewDepartment = ({ attrs: { clientId, departmentId, tab } }) => {
  let department

  const metaItems = []

  getDepartment(clientId, departmentId).then(data => {
    department = data
    if (department.description) {
      metaItems.push({
        icon: pencilIcon,
        label: department.description
      })
    }
    if (department.notes) {
      metaItems.push({
        icon: pencilIcon,
        label: department.notes
      })
    }
    if (department.email) {
      metaItems.push({
        icon: emailIcon,
        label: department.email
      })
    }
  })
  if (!tab) {
    tab = 'Bookings'
  }
  const tabs = ['Bookings', t('app', 'meetings'), 'Venues', 'Users', 'Billing Instructions'].map((t, ix) => {
    return {
      id: ix + 1,
      name: t,
      active: tab && tab === t
    }
  })

  return {
    view ({ attrs: { clientId, departmentId, tab } }) {
      return [
        m('.pr-8', [
          department && m(Breadcrumb, {
            links: [{
              title: 'Clients',
              href: '/clients'
            }, {
              title: department.clientName,
              href: `/clients/${clientId}`
            }, {
              title: 'Departments',
              href: `/clients/${clientId}?tab=Departments`
            }, {
              title: department.name
            }]
          }),
          department && m('.flex.flex-wrap.relative.h-full.overflow-x-hidden.content-start', [
            m('.p-4.w-full', [
              m('div.shadow.sm:rounded-md.divide-y.divide-gray-200', {
              }, [
                m('div.px-4.py-5.bg-white.space-y-6.sm:p-6', [
                  m(PageHeading, {
                    heading: [department.name, m('span.inline-flex.items-center.px-2.5.py-0.5.rounded-full.text-xs.font-medium', {
                      class: department.isActive ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'
                    },
                    department.isActive ? 'Active' : 'Closed'
                    )],
                    subtitle: [m(Svg, { classes: ['w-5', 'h-5'] }, clientsIcon), m(Link, { href: `/clients/${clientId}` }, department.clientName)],
                    metaItems,
                    actions: [
                      {
                        icon: bfIcon,
                        label: 'Booking Fields',
                        action: () => {
                          m.route.set(`/clients/${clientId}/departments/${departmentId}/bookingfields`)
                        }
                      },
                      {
                        icon: editIcon,
                        label: 'Edit Details',
                        action: () => {
                          m.route.set(`/clients/${clientId}/departments/${departmentId}/edit`)
                        }
                      }
                    ]
                  })
                ]),
                m('div.bg-gray-50.px-4.py-5.sm:p-6', [
                  m(Tabs, {
                    tabs,
                    onTabChange: (tabId) => {
                      tabs.forEach(t => {
                        t.active = false
                        if (t.id === tabId) {
                          t.active = true
                          tab = t
                        }
                      })
                      m.redraw()

                      var pageUrl = `/clients/${clientId}/departments/${departmentId}?tab=${tab.name}`
                      window.history.pushState('', '', pageUrl)
                    }
                  }),
                  m('.sm:grid.sm:grid-cols-4.sm:gap-4', [
                    m('div.sm:col-span-4', [
                      tabs.find(t => t.active).id === 1
                        ? m(BookingsTab, {
                          clientId,
                          departmentId,
                          department
                        })
                        : tabs.find(t => t.active).id === 2
                          ? m(MeetingsTab, {
                            clientId,
                            departmentId
                          })
                          : tabs.find(t => t.active).id === 3
                            ? m(VenuesTab, {
                              clientId,
                              departmentId
                            })
                            : tabs.find(t => t.active).id === 4
                              ? m(UsersTab, {
                                clientId,
                                departmentId
                              })
                              : tabs.find(t => t.active).id === 5
                                ? m(BillingInstructionTab, {
                                  clientId,
                                  departmentId
                                })
                                : null
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]),
        m(PastActivityBar, {
          title: t('viewClient', 'activityTitle'),
          request: (data) => {
            getDepartmentActivity(departmentId).then(res => {
              data(res)
              m.redraw()
            })
          }
        })
      ]
    }
  }
}

const BookingsTab = ({ attrs: { clientId, departmentId } }) => {
  const filters = { page: 1, search: null, isActive: 'true' }
  let bookings
  let meta

  let departmentVenues
  getDepartmentBookings(departmentId, filters).then(data => {
    bookings = data.data
    meta = data.meta
  })

  getDepartmentVenues(departmentId, { pagesize: 1000 }).then(data => {
    departmentVenues = data.data
  })

  const getDeptBookings = (departmentId, filters) => {
    bookings = false
    getDepartmentBookings(departmentId, filters).then(data => {
      bookings = data.data
      meta = data.meta
    })
  }

  const filterStatus = (val) => {
    filters.isActive = val
    filters.page = 1
    getDeptBookings(departmentId, filters)
  }

  const debouncedSearch = debounce(val => {
    filters.search = val.length > 2 ? val : null
    filters.page = 1
    getDeptBookings(departmentId, filters)
  }, 300)

  const debouncedSearchReservation = debounce(val => {
    filters.reservationCode = val.length > 2 ? val : null
    filters.page = 1
    getDeptBookings(departmentId, filters)
  }, 300)

  let savingImportApprenticeBookings
  const importApprenticeBookingsOpen = flyd.stream(false)

  const importApprentices = (form) => {
    if (form.departmentVenueId) {
      savingImportApprenticeBookings = true
      importApprenticeBookings(form.departmentVenueId, form.file).then(msg => {
        getDeptBookings(departmentId, filters)
        notification.success({ title: 'Import success', description: msg })
        importApprenticeBookingsOpen(false)
        savingImportApprenticeBookings = false
      }).catch(err => {
        console.log(err)
        notification.error({ title: 'Error - unable to import bookings', description: (err.message ? err.message : '') })
        importApprenticeBookingsOpen(false)
        savingImportApprenticeBookings = false
      })
    }
  }
  let savingImportDLAPiperBookings
  const importDLAPiperBookingsOpen = flyd.stream(false)

  const importDLAPiper = (form) => {
    if (form.departmentVenueId) {
      savingImportDLAPiperBookings = true
      importDLAPiperBookings(form.departmentVenueId, form.file).then(msg => {
        getDeptBookings(departmentId, filters)
        notification.success({ title: 'Import success', description: msg })
        importDLAPiperBookingsOpen(false)
        savingImportDLAPiperBookings = false
      }).catch(err => {
        console.log(err)
        notification.error({ title: 'Error - unable to import bookings' })
        importDLAPiperBookingsOpen(false)
        savingImportDLAPiperBookings = false
      })
    }
  }

  return {
    view ({ attrs: { departmentId, department } }) {
      return m('.grid.grid-cols-1.gap-6.sm:grid-cols-2.lg:grid-cols-1', [
        m('', { style: { height: 'calc(100vh - 23rem)' } }, [
          m('div.shadow.h-full.border-b.border-gray-200.sm:rounded-lg',
            bookings ? m(Table, {
              cols: [
                {
                  label: 'Name',
                  search: true,
                  searchPlaceholder: 'Search name or email...',
                  searchOnChange: (val) => {
                    debouncedSearch(val)
                  }
                },
                {
                  label: 'Email'
                },
                {
                  label: 'Venue Name'
                },
                {
                  label: 'Arrival',
                  classes: ['w-32 justify-center']
                },
                {
                  label: 'Res Code',
                  classes: ['w-32 justify-center'],
                  search: true,
                  searchOnChange: (val) => {
                    debouncedSearchReservation(val)
                  }
                },
                {
                  label: 'Status',
                  classes: ['w-32 justify-center'],
                  filter: true,
                  filterOptions: [
                    { name: 'All', id: '', selected: filters.isActive === '' },
                    { name: 'Active', id: true, selected: filters.isActive === 'true' },
                    { name: 'Closed', id: false, selected: filters.isActive === 'false' }],
                  filterOnChange: (val) => {
                    filterStatus(val)
                  }
                }
              ],
              filters: true
            },
            [
              m('.overflow-auto.flex-grow',
                bookings && bookings.length ? bookings.map((v, ix) => {
                  return m(BookingListItem, { key: v.id, item: v, clientId: clientId, departmentId: departmentId })
                }) : m('p.text-center.p-3.text-gray-400', 'No results')
              ),
              meta && m(Pagination, {
                meta: meta,
                buttons: [
                  m('.flex.justify-end.gap-x-4', [
                    m(Button, {
                      size: 'sm',
                      classes: ['my-2', 'whitespace-nowrap', 'text-white'],
                      type: 'button',
                      onclick: () => {
                        m.route.set(`/clients/${clientId}/departments/${departmentId}/bookings/create`)
                      }
                    }, [
                      m(Svg, {
                        classes: ['w-4', 'mx-auto']
                      }, [addIcon]), 'Add Booking']),
                    department && department.name === 'Apprentices' && m(Button, {
                      size: 'sm',
                      classes: ['my-2', 'whitespace-nowrap', 'text-white'],
                      type: 'button',
                      onclick: () => {
                        importApprenticeBookingsOpen(true)
                      }
                    }, [
                      m(Svg, {
                        classes: ['w-4', 'mx-auto']
                      }, [chevronUp]), 'Import Apprentice Bookings']),
                    department && department.name === 'DLA Piper' && m(Button, {
                      size: 'sm',
                      classes: ['my-2', 'whitespace-nowrap', 'text-white'],
                      type: 'button',
                      onclick: () => {
                        importDLAPiperBookingsOpen(true)
                      }
                    }, [
                      m(Svg, {
                        classes: ['w-4', 'mx-auto']
                      }, [chevronUp]), 'Import Bookings'])
                  ])
                ],
                onpage: (page) => {
                  filters.page = page
                  getDepartmentBookings(departmentId, filters).then(data => {
                    bookings = data.data
                    meta = data.meta
                  })
                }
              }),
              importApprenticeBookingsOpen() &&
              m(ImportApprenticeBookingsDialog, {
                open: importApprenticeBookingsOpen,
                onsubmit: importApprentices,
                loading: savingImportApprenticeBookings,
                venues: departmentVenues
              }),
              importDLAPiperBookingsOpen() &&
              m(ImportDLAPiperBookingsDialog, {
                open: importDLAPiperBookingsOpen,
                onsubmit: importDLAPiper,
                loading: savingImportDLAPiperBookings,
                venues: departmentVenues
              })
            ]) : m(LoadingFullScreen)
          )
        ])
      ])
    }
  }
}

const BookingListItem = ({ attrs: { item, classes, clientId, departmentId } }) => {
  return {
    view () {
      return m('.flex.flex-shrink-0.hover:bg-gray-300.cursor-pointer', {
        class: classes ? classes.join(' ') : 'bg-white',
        style: {
          height: '40px'
        },
        onclick: () => {
          m.route.set(`/clients/${clientId}/departments/${departmentId}/bookings/${item.id}`)
        }
      }, [
        m(Cell,
          item.name
        ),
        m(Cell,
          item.email
        ),
        m(Cell,
          item.venueName
        ),
        m(Cell, { width: 'w-32', align: 'center' },
          DateTime.fromISO(item.arrivalDate).toLocaleString()
        ),
        m(Cell, { width: 'w-32', align: 'center' },
          item.reservationCode
        ),
        m(Cell, { width: 'w-32', align: 'center', noEllipsis: true, padding: 'py-2 px-2' },
          m(BookingStatusBadge, {
            status: item.statusName, size: 'xs', margin: 'm-0'
          }
          )
        )
      ])
    }
  }
}

const MeetingsTab = ({ attrs: { clientId, departmentId } }) => {
  const filters = { page: 1, search: null, isActive: 'true' }
  let meetings
  let meta
  let importResponseData

  getDepartmentMeetings(departmentId, filters).then(data => {
    meetings = data.data
    meta = data.meta
  })

  const getDeptMeetings = (departmentId, filters) => {
    meetings = false
    getDepartmentMeetings(departmentId, filters).then(data => {
      meetings = data.data
      meta = data.meta
    })
  }

  const filterMeetingStatus = (val) => {
    filters.isActive = val
    filters.page = 1
    getDeptMeetings(departmentId, filters)
  }

  const debouncedMeetingSearch = debounce(val => {
    filters.search = val.length > 2 ? val : null
    filters.page = 1
    getDeptMeetings(departmentId, filters)
  }, 300)

  let savingImportMeetings
  const importMeetingsOpen = flyd.stream(false)
  const importResponseOpen = flyd.stream(false)

  const importMeetings = (form) => {
    if (form.file) {
      savingImportMeetings = true
      importMeetingsAPI(departmentId, form.file).then(msg => {
        importResponseData = msg
        importMeetingsOpen(false)
        savingImportMeetings = false
        importResponseOpen(true)
        getDeptMeetings(departmentId, filters)
      }).catch(err => {
        console.log(err)
        notification.error({ title: 'Error - unable to import meetings' })
        savingImportMeetings = false
      })
    } else {
      alert('File must be selected')
    }
  }

  return {
    view ({ attrs: { departmentId } }) {
      return m('.grid.grid-cols-1.gap-6.sm:grid-cols-2.lg:grid-cols-1', [
        m('', { style: { height: 'calc(100vh - 23rem)' } }, [
          m('div.shadow.h-full.border-b.border-gray-200.sm:rounded-lg',
            meetings ? m(Table, {
              cols: [
                {
                  label: 'tobook Ref',
                  classes: ['w-32'],
                  search: true,
                  searchPlaceholder: 'Search tobook ref, description, contact name or email...',
                  filterClasses: 'flex-2',
                  searchOnChange: (val) => {
                    debouncedMeetingSearch(val)
                  }
                },
                {
                  label: 'Description'

                },
                {
                  label: 'Contact Name'
                },
                {
                  label: 'Email'
                },
                {
                  label: 'Venue Name'
                },
                {
                  label: 'Arrival',
                  classes: ['w-32 justify-center']
                },
                {
                  label: 'Status',
                  classes: ['w-32 justify-center'],
                  filter: true,
                  filterOptions: [
                    { name: 'All', id: '', selected: filters.isActive === '' },
                    { name: 'Active', id: true, selected: filters.isActive === 'true' },
                    { name: 'Closed', id: false, selected: filters.isActive === 'false' }],
                  filterOnChange: (val) => {
                    filterMeetingStatus(val)
                  }
                }
              ],
              filters: true
            },
            [
              m('.overflow-auto.flex-grow',
                meetings && meetings.length ? meetings.map((v, ix) => {
                  return m(MeetingListItem, { key: v.id, item: v, clientId: clientId, departmentId: departmentId })
                }) : m('p.text-center.p-3.text-gray-400', 'No results')
              ),
              meta && m(Pagination, {
                meta: meta,
                buttons: [
                  m('.flex.justify-end.gap-x-4', [
                    m(Button, {
                      size: 'sm',
                      classes: ['my-2', 'whitespace-nowrap', 'text-white'],
                      type: 'button',
                      onclick: () => {
                        m.route.set(`/clients/${clientId}/departments/${departmentId}/meetings/create`)
                      }
                    }, [
                      m(Svg, {
                        classes: ['w-4', 'mx-auto']
                      }, [addIcon]), 'Add Meeting']),
                    m(Button, {
                      size: 'sm',
                      classes: ['my-2', 'whitespace-nowrap', 'text-white'],
                      type: 'button',
                      onclick: () => {
                        importMeetingsOpen(true)
                      }
                    }, [
                      m(Svg, {
                        classes: ['w-4', 'mx-auto']
                      }, [chevronUp]), 'Import Meetings'])
                  ])
                ],
                onpage: (page) => {
                  filters.page = page
                  getDepartmentMeetings(departmentId, filters).then(data => {
                    meetings = data.data
                    meta = data.meta
                  })
                }
              }),
              importMeetingsOpen() &&
              m(ImportMeetingsDialog, {
                open: importMeetingsOpen,
                onsubmit: importMeetings,
                loading: savingImportMeetings
              }),
              importResponseOpen() &&
              m(ImportResponseDialog, {
                open: importResponseOpen,
                heading: 'Import Meetings',
                importResponse: importResponseData,
                loading: savingImportMeetings
              })
            ]) : m(LoadingFullScreen)
          )
        ])
      ])
    }
  }
}

const MeetingListItem = ({ attrs: { item, classes, clientId, departmentId } }) => {
  return {
    view () {
      return m('.flex.flex-shrink-0.hover:bg-gray-300.cursor-pointer', {
        class: classes ? classes.join(' ') : 'bg-white',
        style: {
          height: '40px'
        },
        onclick: () => {
          m.route.set(`/clients/${clientId}/departments/${departmentId}/meetings/${item.id}`)
        }
      }, [
        m(Cell,
          { width: 'w-32' },
          item.eventRef
        ),
        m(Cell,
          item.description
        ),
        m(Cell,
          item.name
        ),
        m(Cell,
          item.email
        ),
        m(Cell,
          item.venueName
        ),
        m(Cell, { width: 'w-32', align: 'center' },
          DateTime.fromISO(item.arrivalDate).toLocaleString()
        ),
        m(Cell, { width: 'w-32', align: 'center', noEllipsis: true, padding: 'py-2 px-2' },
          m(MeetingStatusBadge, {
            status: item.statusName, size: 'xs', margin: 'm-0'
          }
          )
        )
      ])
    }
  }
}

const VenuesTab = ({ attrs: { clientId, departmentId } }) => {
  const filters = { page: 1, search: null, isActive: true }
  let venues
  let meta

  getDepartmentVenues(departmentId).then(data => {
    venues = data.data
    meta = data.meta
  })

  const debouncedSearch = debounce(val => {
    filters.search = val.length > 2 ? val : null
    filters.page = 1
    getDepartmentVenues(departmentId, filters).then(data => {
      venues = data.data
      meta = data.meta
    })
  }, 300)
  return {
    view ({ attrs: { departmentId } }) {
      return m('.grid.grid-cols-1.gap-6.sm:grid-cols-2.lg:grid-cols-1', [
        m('', { style: { height: 'calc(100vh - 23rem)' } }, [
          m('div.shadow.h-full.border-b.border-gray-200.sm:rounded-lg',
            venues && m(Table, {
              cols: [
                {
                  label: 'Venue Name',
                  search: true,
                  searchOnChange: (val) => {
                    debouncedSearch(val)
                  }
                },
                {
                  label: 'Commission',
                  classes: ['w-32 justify-end']
                },
                {
                  label: t('app', 'accommodation'),
                  classes: ['w-32 justify-center']
                },
                {
                  label: t('app', 'meetings'),
                  classes: ['w-32 justify-center']
                }
              ],
              filters: true
            },
            [
              m('.overflow-auto.flex-grow',
                venues.length ? venues.map((v, ix) => {
                  return m(VenueListItem, { key: v.id, item: v, clientId: clientId, departmentId: departmentId })
                }) : m('p.text-center.p-3.text-gray-400', 'No results')
              ),
              meta && m(Pagination, {
                meta: meta,
                buttons: [
                  m('.flex.justify-end', [
                    m(Button, {
                      size: 'sm',
                      classes: ['my-2', 'whitespace-nowrap', 'text-white'],
                      type: 'button',
                      onclick: () => {
                        m.route.set(`/clients/${clientId}/departments/${departmentId}/venues/create`)
                      }
                    }, [
                      m(Svg, {
                        classes: ['w-4', 'mx-auto']
                      }, [addIcon]), 'Add venue'])
                  ])],
                onpage: (page) => {
                  filters.page = page
                  getDepartmentVenues(departmentId, filters).then(data => {
                    venues = data.data
                    meta = data.meta
                  })
                }
              })
            ])
          )
        ])
      ])
    }
  }
}

const VenueListItem = ({ attrs: { item, classes, clientId, departmentId } }) => {
  return {
    view () {
      return m('.flex.flex-shrink-0.hover:bg-gray-300.cursor-pointer', {
        class: classes ? classes.join(' ') : 'bg-white',
        style: {
          height: '40px'
        },
        onclick: () => {
          m.route.set(`/clients/${clientId}/departments/${departmentId}/venues/${item.id}`)
        }
      }, [
        m(Cell,
          item.venueName
        ),
        m(Cell, { width: 'w-32', align: 'right' },
          (item.commission * 100).toFixed(2) + ' %'
        ),
        m(Cell, { width: 'w-32', align: 'center' },
          m(VisibilityBadge, { visibility: item.accommodationVisibilityName, size: 'sm' })
        ),
        m(Cell, { width: 'w-32', align: 'center' },
          m(VisibilityBadge, { visibility: item.meetingVisibilityName, size: 'sm' })
        )
      ])
    }
  }
}

const UsersTab = ({ attrs: { clientId, departmentId } }) => {
  let users

  getDepartmentUsers(departmentId).then(data => {
    users = data
  })
  return {
    view () {
      return m('.grid.grid-cols-1.gap-6.sm:grid-cols-2.lg:grid-cols-3.pt-3', [
        users && users.length ? users.sort((x, y) => (x.isActive === y.isActive) ? 0 : x.isActive ? -1 : 1).map(user => {
          return m('.col-span-1', [
            m(UserCard, { clientId, departmentId, user })
          ])
        }) : m('p.col-span-3.text-center.p-3.text-gray-400', ''),
        m('.flex.col-span-3.justify-center.gap-6', [
          m(Button, {
            size: 'sm',
            classes: ['my-2', 'whitespace-nowrap', 'text-white'],
            type: 'button',
            onclick: () => {
              m.route.set(`/clients/${clientId}/departments/${departmentId}/users/create`)
            }
          }, [
            m(Svg, {
              classes: ['w-4', 'mx-auto']
            }, [addIcon]), 'Add Department User']),
          m(Button, {
            size: 'sm',
            classes: ['my-2', 'whitespace-nowrap', 'text-white'],
            type: 'button',
            onclick: () => {
              m.route.set(`/clients/${clientId}/departments/${departmentId}/delegate-users/create`)
            }
          }, [
            m(Svg, {
              classes: ['w-4', 'mx-auto']
            }, [addIcon]), 'Add Delegate'])
        ])
      ])
    }
  }
}

const UserCard = () => {
  return {
    view ({ attrs: { clientId, departmentId, user } }) {
      return m(Card, {
        onclick: () => {
          if (user.isDelegate) { m.route.set(`/clients/${clientId}/departments/${departmentId}/delegate-users/${user.id}`) } else { m.route.set(`/clients/${clientId}/departments/${departmentId}/users/${user.id}`) }
        },
        clickable: true
      }, [
        m('.flex.items-center.justify-between', [
          m('div', [
            m('p', user.name),
            m('p.text-sm.text-gray-600', user.isDelegate ? user.userName : user.email),
            !user.isDelegate && user.email !== user.userName && m('p.text-sm.text-gray-600', user.userName),
            m('p.text-sm.text-gray-600.mt-2', [
              m('span.inline-flex.items-center.px-2.5.py-0.5.rounded-full.text-xs.font-medium', {
                class: user.isDelegate ? 'bg-purple-200 text-purple-800' : 'bg-gray-200 text-gray-800'
              },
              user.isDelegate ? 'Delegate' : 'User'
              )
            ]),
            !user.isConfirmed && m('p.text-sm.text-gray-600.mt-2', [
              m('span.inline-flex.items-center.rounded-full.text-xs.font-medium', {
                class: 'px-2 py-0.5 bg-red-200 text-red-800'
              },
              'Account pending'
              )
            ])
          ]),
          m(Avatar, { name: user.name })
        ])
      ])
    }
  }
}

const BillingInstructionTab = ({ attrs: { clientId, departmentId } }) => {
  const filters = { search: null }
  let billingIntructions

  getDepartmentBillingInstructions(departmentId).then(data => {
    billingIntructions = data
  })

  const debouncedSearch = debounce(val => {
    filters.search = val.length > 2 ? val : null
    getDepartmentBillingInstructions(departmentId, filters).then(data => {
      billingIntructions = data
    })
  }, 300)

  return {
    view ({ attrs: { departmentId } }) {
      return m('.grid.grid-cols-1.gap-6.sm:grid-cols-2.lg:grid-cols-1', [
        m('', { style: { height: 'calc(100vh - 23rem)' } }, [
          m('div.shadow.h-full.border-b.border-gray-200.sm:rounded-lg',
            billingIntructions && m(Table, {
              cols: [
                {
                  label: 'Description',
                  search: true,
                  searchOnChange: (val) => {
                    debouncedSearch(val)
                  }
                }
              ],
              filters: true
            },
            [
              m('.overflow-auto.flex-grow',
                billingIntructions.length ? billingIntructions.map((v, ix) => {
                  return m(BillingInstructionListItem, { key: v.id, item: v, clientId: clientId, departmentId: departmentId })
                }) : m('p.text-center.p-3.text-gray-400', 'No results')
              ),
              m('.flex.col-span-3.justify-center.gap-6', [
                m(Button, {
                  size: 'sm',
                  classes: ['my-2', 'whitespace-nowrap', 'text-white'],
                  type: 'button',
                  onclick: () => {
                    m.route.set(`/clients/${clientId}/departments/${departmentId}/billing/create`)
                  }
                }, [
                  m(Svg, {
                    classes: ['w-4', 'mx-auto']
                  }, [addIcon]), 'Add Billing Instruction'])
              ])
            ])
          )

        ])
      ])
    }
  }
}

const BillingInstructionListItem = ({ attrs: { item, classes, clientId, departmentId } }) => {
  return {
    view () {
      return m('.flex.flex-shrink-0.hover:bg-gray-300.cursor-pointer', {
        class: classes ? classes.join(' ') : 'bg-white',
        style: {
          height: '40px'
        },
        onclick: () => {
          m.route.set(`/clients/${clientId}/departments/${departmentId}/billing/${item.id}`)
        }
      }, [
        m(Cell,
          item.description
        )
      ])
    }
  }
}
